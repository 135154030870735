<template>
  <b-sidebar
    id="sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    no-close-on-backdrop
    right
    @shown="loadData"
    @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0 text-capitalize">
          {{ (isAdd ? $t("actions.add") : $t("actions.edit")) + ' ' + $tc("swift_sensor.title", 1) }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm"
        >

          <!-- Name -->
          <validation-provider
            #default="validationContext"
            name="name"
            rules="required"
          >
            <b-form-group
              class="text-capitalize"
              :label="$t('dahua_dvr.name')"
              label-for="name"
            >
              <b-form-input
                id="name"
                v-model="itemData.name"
                :state="getValidationState(validationContext)"
                trim
                disabled
              />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Unit -->
          <validation-provider #default="validationContext" name="Unit">
            <b-form-group
              class="text-capitalize"
              :label="$tc('unit.title', 2)"
              label-for="id_unit"
              :state="getValidationState(validationContext)"
            >
              <select-paginate
                v-model="itemData.id_unit"
                :items="unitOptions"
                input-id="id_unit"
                :multiple="true"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Center -->
          <validation-provider #default="validationContext" name="Center">
            <b-form-group
              class="text-capitalize"
              :label="$tc('centers.title', 1)"
              label-for="id_center"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_center"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="centerOptions"
                :reduce="(val) => val.value"
                input-id="id_center"
                @input="changeCenter"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Sector -->
          <validation-provider #default="validationContext" name="Sector">
            <b-form-group
              label="Sector"
              label-for="id_sector"
              :state="getValidationState(validationContext)"
            >
              <v-select
                v-model="itemData.id_sector"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="sectorOptions"
                :reduce="(val) => val.value"
                input-id="id_sector"
              />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2 text-capitalize"
              type="submit"
            >
              {{ isAdd ? $t("actions.add") :$t("actions.edit") }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
              class="text-capitalize"
            >
             {{$t('actions.cancel')}}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
  BInputGroupAppend,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { computed, ref } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import SelectPaginate from "../components/SelectPaginate.vue";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BInputGroupAppend,
    BButton,
    vSelect,
    BFormCheckbox,
    SelectPaginate,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: false,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
    centerOptions: {
      type: Array,
      required: true,
    },
    sectorOptions: {
      type: Array,
      required: true,
    },
    unitOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {
    const blankData = {
      id_center: null,
      id_sector: null,
      id_unit: null
    };

    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
      store.dispatch("sector/getAll");
    };

    const onSubmit = () => {
      let dispatch;

      const req = {
        id: itemData.value.id,
        params: {
          id_unit:
            itemData.value.id_unit.length > 0
              ? itemData.value.id_unit.map((unit) => unit.value)
              : null,
          id_center: itemData.value.id_center || null,
          id_sector: itemData.value.id_sector || null,
        },
      };

      if (props.isAdd) {
        dispatch = store.dispatch("swift_sensor/add", itemData.value);
      } else {
        dispatch = store.dispatch("swift_sensor/edit", req);
      }

      dispatch
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData);

    const changeCenter = () => {
      store
        .dispatch("sector/getAll", {
          id_center: [itemData.value.id_center],
        })
        .then(() => {
          const sector = props.sectorOptions.filter(
            (item) => item.value == itemData.value.id_sector
          )

          if (sector.length == 0) itemData.value.id_sector = ""
        })
    }

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit;

        if (props.itemEdit.units) {
          itemData.value.id_unit = props.itemEdit.units.map((value) => ({
            value: value.id,
            label: value.description,
          }));
        }
        
        itemData.value.id_center = props.itemEdit.center?.id;
        itemData.value.id_sector = props.itemEdit.sector?.id;
        changeCenter();
      }
    };

    return {
      itemData,
      onSubmit,
      changeCenter,
      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
    };
  },
};
</script>

<style lang="scss">
.b-time-footer {
  padding: 0.5rem;
}
  > [role="dialog"] {
  height: 160px;
}
</style>
