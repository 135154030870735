<template>
  <b-card no-body class="m-2">
    <b-card-header class="pb-50 text-capitalize">
      <h5>{{$t('filter')}}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col
          cols="6"
          md="6"
          class="mb-md-0 mb-2"
        >
          <label class="text-capitalize">{{$tc('centers.title', 2)}}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="centerFilter"
            :options="centerOptions"
            class="w-100"
            :reduce="val => val.value"
            multiple
            @input="(val) => $emit('update:centerFilter', val)"
          />
        </b-col>
        <b-col
          cols="6"
          md="6"
          class="mb-md-0 mb-2"
        >
          <label class="text-capitalize">{{ $tc('sectors.title', 2) }}</label>
          <v-select
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :value="sectorFilter"
            :options="sectorOptions"
            class="w-100"
            :reduce="val => val.value"
            multiple
            @input="(val) => $emit('update:sectorFilter', val)"
          />
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    vSelect,
  },
  props: {
    centerOptions: {
      type: Array,
      default: null,
    },
    centerFilter: {
      type: Array,
      default: null,
    },
    sectorOptions: {
      type: Array,
      default: null,
    },
    sectorFilter: {
      type: Array,
      default: null,
    },
  },
};
</script>