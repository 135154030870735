import { ref, computed, watch } from '@vue/composition-api'
// import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import router from '@/router'
import ability from '@/libs/acl/ability'
import { debounce } from 'lodash'

export default function useList() {
  const refListTable = ref(null)

  const toast = useToast()

  store.dispatch('center/getAll')
  store.dispatch('sector/getAll')
  store.dispatch('unit/getAll', { with: 'router:id,id_tenant' })

  const centerOptions = computed(() => store.getters['center/getSelectedItems'])
  const centerFilter = ref(null)
  const sectorOptions = computed(() => store.getters['sector/getSelectedItems'])
  const sectorFilter = ref(null)
  const unitOptions = computed(() => store.getters['unit/getSelectedItems'])

  const tableColumns = [
    { label: 'swift_sensor.name', key: 'name', sortable: true },
    { label: 'swift_sensor.description', key: 'description', sortable: true },
    {
      label: 'unit.title', key: 'units', sortable: true,
      formatter: (value, key, item) => {
        if (item.units) return [...new Set(value.map(item => { return item.description }))].join(', ')
        return ""
      }
    },
    { label: 'centers.title', key: 'center.name', sortable: true },
    { label: 'sectors.title', key: 'sector.name', sortable: true },
    { label: 'swift_sensor.id_external', key: 'id_external', sortable: true },
    { label: 'swift_sensor.first_contact_time', key: 'first_contact_time', sortable: true },
    { label: 'swift_sensor.last_contact_time', key: 'last_contact_time', sortable: true },
    { label: 'swift_sensor.id_hardware', key: 'id_hardware', sortable: true },
    { label: 'swift_sensor.latitude', key: 'latitude', sortable: true },
    { label: 'swift_sensor.longitude', key: 'longitude', sortable: true },
    { label: 'swift_sensor.elevation', key: 'elevation', sortable: true },
    { label: 'swift_sensor.batery_level', key: 'batery_level', sortable: true },
    { label: 'swift_sensor.is_new', key: 'is_new', sortable: true },
    { label: 'swift_sensor.signal_strength', key: 'signal_strength', sortable: true },
    { label: 'swift_sensor.wireless_type', key: 'wireless_type', sortable: true },
    { label: 'swift_sensor.sample_rate', key: 'sample_rate', sortable: true },
    { label: 'swift_sensor.manufacturer', key: 'manufacturer', sortable: true },
    { label: 'swift_sensor.model', key: 'model', sortable: true },
    { label: 'swift_sensor.hardware_revision', key: 'hardware_revision', sortable: true },
    { label: 'swift_sensor.software_revision', key: 'software_revision', sortable: true },
    { label: 'swift_sensor.is_event_based', key: 'is_event_based', sortable: true },
    { label: 'swift_sensor.id_sensor', key: 'id_sensor', sortable: true },
    { label: 'swift_sensor.id_threshold', key: 'id_threshold', sortable: true },
    { label: 'swift_sensor.id_swift_gateway', key: 'id_swift_gateway', sortable: true },
    { label: 'swift_sensor.id_unit_measure', key: 'id_unit_measure', sortable: true },
    { label: 'swift_sensor.id_type_sensor', key: 'id_type_sensor', sortable: true },
    { label: 'swift_sensor.offset', key: 'offset', sortable: true },
    { label: 'date.created_at', key: 'created_at', sortable: true, formatter: 'formatDateAssigned' },
    { label: 'date.updated_at', key: 'updated_at', sortable: true, formatter: 'formatDateAssigned' }
  ]

  if (ability.can('update', 'SwiftSensor') || ability.can('delete', 'SwiftSensor') ) {
    tableColumns.splice(0, 0, { label: 'actions.name', key: 'actions' })
  }

  const perPage = ref(10)
  const totalList = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalList.value,
    }
  })

  const fetchList = (ctx, callback) => {
    store.dispatch('swift_sensor/getAll', {
      q: searchQuery.value,
      max: perPage.value,
      'page[n]': currentPage.value,
      sortBy: sortBy.value,
      sortDesc: isSortDirDesc.value,
      center: centerFilter.value,
      sector: sectorFilter.value,
    })
      .then(response => {
        callback(response.data)
        totalList.value = response.meta.total
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error obteniendo la información',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const formatDateAssigned = value => {
    const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
    return formattedDate
  }

  const refetchData = () => {
    refListTable.value.refresh()
  }

  watch([ centerFilter, sectorFilter, currentPage, perPage ], () => {
    refetchData()
  })

  watch([ searchQuery ], debounce(() => {
    refetchData()
  }, 500))

  return {
    tableColumns,
    perPage,
    currentPage,
    totalList,
    fetchList,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refListTable,
    formatDateAssigned,

    centerOptions,
    centerFilter,
    sectorOptions,
    sectorFilter,
    unitOptions,

    refetchData,
  }
}
